import { render, staticRenderFns } from "./svg-sprite-view.vue?vue&type=template&id=01b64bb0&"
import script from "./svg-sprite-view.vue?vue&type=script&lang=ts&"
export * from "./svg-sprite-view.vue?vue&type=script&lang=ts&"
import style0 from "./svg-sprite-view.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01b64bb0')) {
      api.createRecord('01b64bb0', component.options)
    } else {
      api.reload('01b64bb0', component.options)
    }
    module.hot.accept("./svg-sprite-view.vue?vue&type=template&id=01b64bb0&", function () {
      api.rerender('01b64bb0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/route-components/svg-sprite-view.vue"
export default component.exports